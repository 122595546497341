import React from 'react'
import { Text as StyledText, Heading } from 'rebass/styled-components'
import styled from 'styled-components'

export const A = styled.a`
  color: ${props => props.theme.colors.primary};
`

export const Underline = styled.span`
  position: relative;

  &:after {
    background-color: #fddfd8;
    opacity: 0.3;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.3em;
    bottom: 0.2em;
    z-index: -1;
  }
`

export const Text = props => <StyledText variant='content' {...props} />
export const P = props => <StyledText as='p' variant='p' {...props} />
export const H1 = props => <Heading as='h1' variant='h1' {...props} />
export const H2 = props => <Heading as='h2' variant='h2' {...props} />
export const H3 = props => <Heading as='h3' variant='h3' {...props} />
export const H4 = props => <Heading as='h4' variant='h4' {...props} />
export const H5 = props => <Heading as='h5' variant='h5' {...props} />
export const H6 = props => <Heading as='h6' variant='h6' {...props} />
