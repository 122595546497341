import Cabin from './cabin.json'
import Clients from './clients.json'
import HowWeWork1 from './how-we-work-1.json'
import HowWeWork2 from './how-we-work-2.json'
import HowWeWork3 from './how-we-work-3.json'
import Line1 from './line-1.json'
import Line2 from './line-2.json'
import Line3 from './line-3.json'
import LineMobile1 from './line-mobile-1.json'
import LineMobile2 from './line-mobile-2.json'
import WhatIsForge from './what-is-forge.json'
import Logo from './logo.json'
import Quote from './quote.json'
import People from './people.json'
import Venn from './venn.json'
import Partners from './partners.json'

export default {
  Cabin,
  Clients,
  HowWeWork1,
  HowWeWork2,
  HowWeWork3,
  Line1,
  Line2,
  Line3,
  LineMobile1,
  LineMobile2,
  WhatIsForge,
  Logo,
  Quote,
  People,
  Venn,
  Partners
}
