import React from 'react'
import LottieOriginal from 'react-lottie'
import { Waypoint } from 'react-waypoint'
// import * as whatIsForge from '../components/bodymovin/what-is-forge.json'
import animationData from './bodymovin'

class Lottie extends LottieOriginal {
  componentDidUpdate(prevProps, prevState, prevContext) {
    if (super.componentDidUpdate) {
      super.componentDidUpdate(prevProps, prevState, prevContext)
    }

    if (!this.props.scrollControl) {
      return
    }

    if (this.props.progress !== prevProps.progress && this.anim.totalFrames) {
      const frame = Math.round(
        this.anim.totalFrames * this.props.progress * 1.2 - 2
      )
      this.anim.goToAndStop(frame, true)
    }
  }
}

class Animation extends React.Component {
  constructor() {
    super()
    this.state = {
      isStopped: true,
      progress: 0
    }
  }

  startAnimation = () => {
    this.setState({
      isStopped: false
    })
  }

  onEnter = () => {
    console.log('Start Animation', this.props.name)
    if (this.props.scrollControl) {
      return
    }
    if (!this.props.delay) {
      this.startAnimation()
    } else {
      setTimeout(this.startAnimation, this.props.delay || 0)
    }
  }

  onLeave = () => {
    this.setState({
      isStopped: true
    })
    this.lottie.anim.goToAndStop(0, true)
  }

  onProgress = ({ progress, velocity }, ref) => {
    if (!this.props.scrollControl) {
      return
    }
    this.setState({
      progress
    })
  }

  render() {
    return (
      <Waypoint
        topOffset={this.props.topOffset || '55%'}
        bottomOffset={this.props.bottomOffset || '120%'}
        onEnter={this.onEnter}
        style={{ width: '100%', height: '100%' }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <Lottie
            ref={r => (this.lottie = r)}
            progress={this.state.progress}
            scrollControl={this.props.scrollControl}
            speed={this.props.speed || 1}
            width={'100%'}
            height={'100%'}
            isStopped={this.state.isStopped}
            options={{
              loop: this.props.loop || false,
              autoplay: false,
              animationData: animationData[this.props.name],
              renderer: 'svg',
              rendererSettings: {
                preserveAspectRatio: this.props.aspectRatio || 'xMidYMid meet'
              }
            }}
          />
        </div>
      </Waypoint>
    )
  }
}

export default Animation
